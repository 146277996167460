import React from 'react'
// Components
import { Seo, PageHeader } from '../components'
// Context
import { usePrismic } from '../context/PrismicContext'
// Styles
import {
  Container,
  ListItem,
  StyledImage,
  StyledList,
  StyledOlList,
  StyledSpan,
  Title,
  Wrapper,
} from '../styles/CorporatePage.style'
// Utils
import { formatParagraph } from '../utils/prismicUtils'

const Science = () => {
  const {
    prismicData: {
      prismicSciencePage: {
        about_quantity_content,
        about_quantity_title,
        biosynq_content,
        biosynq_image,
        doctor_image,
        doctor_overview,
        page_title,
        products_content,
        products_title,
        qcore_content,
        qcore_image,
        qcore_title,
        quintessential_content,
        quintessential_title,
      },
    },
  } = usePrismic()

  const formatContent = content => {
    let isListOpened = false
    const olListItems = []
    const ulListItems = []

    return content.map((item, index) => {
      if (item.type === 'o-list-item' || item.type === 'list-item') {
        isListOpened = true

        if (item.type === 'o-list-item') {
          olListItems.push(item)
        } else {
          ulListItems.push(item)
        }

        if (index !== content.length - 1) {
          return <></>
        } else {
          if (olListItems.length > 0) {
            return (
              <StyledOlList>
                {olListItems.map(listItem => (
                  <ListItem>
                    <StyledSpan
                      dangerouslySetInnerHTML={{
                        __html: formatParagraph(listItem),
                      }}
                    />
                  </ListItem>
                ))}
              </StyledOlList>
            )
          } else if (ulListItems.length > 0) {
            return (
              <StyledList>
                {ulListItems.map(listItem => (
                  <ListItem>
                    <StyledSpan
                      dangerouslySetInnerHTML={{
                        __html: formatParagraph(listItem),
                      }}
                    />
                  </ListItem>
                ))}
              </StyledList>
            )
          }
        }
      } else {
        if (isListOpened) {
          isListOpened = false

          if (olListItems.length > 0) {
            return (
              <>
                <StyledOlList>
                  {olListItems.map(listItem => (
                    <ListItem>
                      <StyledSpan
                        dangerouslySetInnerHTML={{
                          __html: formatParagraph(listItem),
                        }}
                      />
                    </ListItem>
                  ))}
                </StyledOlList>
                <StyledSpan
                  key={`sciences-${index}`}
                  dangerouslySetInnerHTML={{ __html: formatParagraph(item) }}
                />
              </>
            )
          } else if (ulListItems.length > 0) {
            return (
              <>
                <StyledList>
                  {ulListItems.map(listItem => (
                    <ListItem>
                      <StyledSpan
                        dangerouslySetInnerHTML={{
                          __html: formatParagraph(listItem),
                        }}
                      />
                    </ListItem>
                  ))}
                </StyledList>
                <StyledSpan
                  key={`sciences-${index}`}
                  dangerouslySetInnerHTML={{ __html: formatParagraph(item) }}
                />
              </>
            )
          }
        }

        olListItems.splice(0, olListItems.length)
        ulListItems.splice(0, ulListItems.length)

        return (
          <StyledSpan
            key={`sciences-${index}`}
            dangerouslySetInnerHTML={{ __html: formatParagraph(item) }}
          />
        )
      }
    })
  }

  return (
    <>
      <Seo title={page_title[0].text} />
      <Wrapper>
        <PageHeader exitRoute="/">{quintessential_title[0].text}</PageHeader>
        <Container black padded>
          <Title align="left" margin="0">
            {quintessential_title[0].text}
          </Title>
          {formatContent(quintessential_content)}
        </Container>

        <Container padded noPaddingBottom>
          <Title bigger>{products_title[0].text}</Title>
        </Container>

        <Container padded>{formatContent(products_content)}</Container>

        <Container>
          <StyledImage src={doctor_image.url} />
        </Container>
        <Container padded noPaddingBottom>
          {formatContent(doctor_overview)}
        </Container>

        <Container>
          <StyledImage src={biosynq_image.url} />
        </Container>
        <Container padded>{formatContent(biosynq_content)}</Container>

        <Container>
          <StyledImage src={qcore_image.url} />
        </Container>
        <Container black padded noPaddingBottom>
          <Title>{qcore_title[0].text}</Title>
        </Container>
        <Container black padded>
          {formatContent(qcore_content)}
        </Container>

        <Container padded noPaddingBottom>
          <Title>{about_quantity_title[0].text}</Title>
        </Container>
        <Container padded noPaddingBottom>
          {formatContent(about_quantity_content)}
        </Container>
      </Wrapper>
    </>
  )
}

export default Science
